import { Avatar, AvatarProps } from "@chakra-ui/react";
import { useUser } from "../providers/useUser";

const AvatarSB = ({
  bgColor = "primary.500",
  boxSize = "40px",
  ...props
}: AvatarProps): JSX.Element => {
  const { user } = useUser();

  return (
    <Avatar
      bgColor={user?.avatarUrl ? "none" : bgColor}
      boxSize={boxSize}
      color={"white"}
      name={`${user?.name?.split(" ")[0] ?? ""} ${
        user?.lastName?.split(" ")[0] ?? ""
      }`}
      size={"sm"}
      pt={"1px"}
      src={user?.avatarUrl ?? ""}
      {...props}
    />
  );
};

export default AvatarSB;
