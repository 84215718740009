import { Ico } from "@/assets/icons";
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Trans } from "react-i18next";

interface MainFilterProps {
  selectedTeacher: string;
  setSelectedTeacher: (value: string) => void;
  selectedLevel: string;
  setSelectedLevel: (value: string) => void;
  selectedBootcamp: string;
  setSelectedBootcamp: (value: string) => void;
  teachers: string[];
  levels: string[];
  bootcamps: string[];
}

const arrows = {
  up: <Ico.ChevronUp fontSize={"20px"} color="#1F58F3" />,
  down: <Ico.ChevronDown fontSize={"20px"} color="#1F58F3" />,
};

type arrowDirection = "up" | "down";

const MainFilter: React.FC<MainFilterProps> = ({
  selectedTeacher,
  setSelectedTeacher,
  selectedLevel,
  setSelectedLevel,
  teachers,
  levels,
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const desireOrder = ["Basico", "Medio", "Avanzado"];
  const levelsInOrder = levels.sort(
    (a, b) => desireOrder.indexOf(a) - desireOrder.indexOf(b)
  );
  const [mentorArrowDirection, setMentorArrowDirection] =
    useState<arrowDirection>("down");
  const [levelArrowDirection, setLevelArrowDirection] =
    useState<arrowDirection>("down");

  const mentorFilterIcon = arrows[mentorArrowDirection];
  const levelFilterIcon = arrows[levelArrowDirection];

  return (
    <Flex
      w={isMobile ? "100%" : "auto"}
      p={4}
      gap={5}
      my={isMobile ? 0 : 4}
      justifyContent={isMobile ? "center" : "flex-start"}
    >
      <Menu
        onClose={() => setMentorArrowDirection("down")}
        onOpen={() => setMentorArrowDirection("up")}
      >
        <MenuButton>
          <Flex alignItems={"center"} gap={2}>
            <Text variant={"placeholder"}>
              {selectedTeacher !== "Todos" ? (
                <Text variant={"placeholder"}>{selectedTeacher}</Text>
              ) : (
                <Trans>Mentores</Trans>
              )}
            </Text>

            {mentorFilterIcon}
          </Flex>
        </MenuButton>

        <MenuList maxH={"200px"} overflowY={"auto"}>
          {teachers.map((teacher) => (
            <MenuItem key={teacher} onClick={() => setSelectedTeacher(teacher)}>
              {teacher}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Menu
        onClose={() => setLevelArrowDirection("down")}
        onOpen={() => setLevelArrowDirection("up")}
      >
        <MenuButton>
          <Flex alignItems={"center"} gap={2}>
            <Text variant={"placeholder"}>
              {selectedLevel !== "Todos" ? (
                <Text variant={"placeholder"}>{selectedLevel}</Text>
              ) : (
                <Trans>Niveles</Trans>
              )}
            </Text>
            {levelFilterIcon}
          </Flex>
        </MenuButton>
        <MenuList>
          {levelsInOrder.map((level) => (
            <MenuItem key={level} onClick={() => setSelectedLevel(level)}>
              {level}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default MainFilter;
