import { PageContainer } from "@/components/PageContainer";
import OnlinePrograms from "../../OnlinePrograms";
import LiveSessions from "../LiveClasses/LiveSessions";
import GuideTraining from "../../GuideTraining";
import SubCategoryFilter from "./subCategoryFilter/subCategoryFilter";
import BrandFilter from "./brandFilter/brandFilter";
import { Divider, Flex, useBreakpointValue } from "@chakra-ui/react";
import StartGuide from "./startGuide";
import { ContinueProgress } from "@/components/ContinueToProgress/continueProgress";
import { LearnParams } from "./providers/types";
import { useParams } from "react-router-dom";
import OnboardingBanner from "@/components/OnboardingBanner";
import { useContext, useState, useMemo } from "react";
import { onboardingContext } from "./providers/onboardingProvider";
import ModalOnboarding from "@/components/ModalOnboarding";
import BannerBCMM from "@/components/BannerBC&MM/BannerBC&MM";
import useBannerBCMM from "@/components/BannerBC&MM/useBannerBCMM";
import { learnContext } from "./providers/learnProvider";
import MentorLevelFilter from "./mentorLevelFilter";

const Learn = () => {
  const { subroute: selectedSubpage } = useParams<LearnParams>();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const {
    isOnboardingOpen,
    setOnboardingOpen,
    isComponentVisible,
    validateIsScheduled,
    quantityScheduled,
  } = useContext(onboardingContext);

  const prov = useContext(learnContext);

  const { shouldShowBanner, data: bannerData } = useBannerBCMM({
    productsBanner: prov.bootcamps?.myBootcamps ?? [],
  });

  // State for mentor level filters
  const [selectedLevel, setSelectedLevel] = useState<string>("Todos");
  const [selectedTeacher, setSelectedTeacher] = useState<string>("Todos");
  const [selectedBootcamp, setSelectedBootcamp] = useState<string>("Todos");

  // Derive unique levels from programs
  const levels = useMemo(() => {
    const allLevels = new Set(
      prov.programs.map((program) => program.level?.[0]?.name).filter(Boolean)
    );
    return ["Todos", ...Array.from(allLevels)];
  }, [prov.programs]);

  // Derive unique teachers from programs
  const teachers = useMemo(() => {
    const allTeachers = new Set(
      prov.programs
        .flatMap((program) =>
          program.userMentors?.map((mentor: { name: string }) => mentor.name)
        )
        .filter(Boolean)
    );
    return ["Todos", ...Array.from(allTeachers)];
  }, [prov.programs]);

  // Derive unique bootcamps
  const uniqueBootcamps = useMemo(() => {
    const allBootcamps = new Set(
      prov.bootcamps.myBootcamps.map((bootcamp) => bootcamp.name)
    );
    return ["Todos", ...Array.from(allBootcamps)];
  }, [prov.bootcamps]);

  return (
    <PageContainer
      noGap
      px={isMobile ? 0 : "32px"}
      mt={isMobile ? "24px" : "40px"}
      pb={isMobile ? "80px" : "136px"}
    >
      {isComponentVisible || (validateIsScheduled && quantityScheduled > 0) ? (
        <Flex
          justifyContent={"center"}
          mt={4}
          mb={4}
          px={isMobile ? "16px" : "0"}
        >
          <OnboardingBanner />
        </Flex>
      ) : null}

      <Flex px={isMobile ? "16px" : "0"} mt={isComponentVisible ? 4 : 8} mb={4}>
        {shouldShowBanner ? (
          <BannerBCMM bannerData={bannerData} />
        ) : (
          <ContinueProgress />
        )}
      </Flex>

      <Flex
        px={isMobile ? "16px" : 0}
        gap={isMobile ? "16px" : "18px"}
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        w={"100%"}
      >
        {isMobile && !selectedSubpage && <StartGuide />}

        {selectedSubpage ? <SubCategoryFilter /> : <BrandFilter />}
      </Flex>

      <Flex py={4} justifyContent={"flex-end"} alignItems={"center"}>
        <MentorLevelFilter
          levels={levels}
          teachers={teachers}
          bootcamps={uniqueBootcamps}
          selectedLevel={selectedLevel}
          selectedTeacher={selectedTeacher}
          selectedBootcamp={selectedBootcamp}
          setSelectedLevel={setSelectedLevel}
          setSelectedTeacher={setSelectedTeacher}
          setSelectedBootcamp={setSelectedBootcamp}
        />

        {!isMobile && !selectedSubpage && <StartGuide />}
      </Flex>

      <Divider borderBottomWidth={"0.5px"} />
      <LiveSessions />
      <Divider borderBottomWidth={"0.5px"} />
      <OnlinePrograms />
      <Divider borderBottomWidth={"0.5px"} />
      <GuideTraining />
      <ModalOnboarding
        isOpen={isOnboardingOpen}
        onClose={() => {
          setOnboardingOpen(false);
        }}
      />
    </PageContainer>
  );
};

export default Learn;
